define('tm-common/models/matter-fields-history', ['exports', 'ember-data', 'tm-common/models/matter-field'], function (exports, _emberData, _matterField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = _matterField.default.extend({
        createdBy: belongsTo('user'),
        createdOn: attr('estdate'),
        createdReason: attr('string')
    });
});